import ExperianceCarousel from 'components/ExperianceCarousel/ExperianceCarousel'
import Introduction from 'components/Introduction/Introduction'
import React, { useEffect } from 'react'
import "layout/MainContent/MainContent.css"
import Skills from 'components/Skills/Skills'
import Projects from 'components/Projects/Projects'
import ContactMe from 'components/ContactMe/ContactMe'
import Footer from 'components/Footer/Footer'
import { scroller } from 'react-scroll'



function MainContent() {

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if(entry.isIntersecting){
        if(entry.target.classList.contains("IntroductionSecondHalfContainer")){
          entry.target.classList.add('intersecting');
        }
        else{
          entry.target.classList.add('intersecting');
        }
      } else {
        entry.target.classList.remove('intersecting');
        entry.target.classList.remove('intersecting');
      }
    })
  })


  useEffect(() => {
    const observeElements = document.querySelectorAll('.ObserveEntity');
    observeElements.forEach((el) => observer.observe(el))
    return () => {
      observeElements.forEach((el) => observer.unobserve(el));
    };
  },[])


  useEffect(() => {
    // Check the flag in localStorage to see if we should scroll to the ContactMe section
    const scrollToContact = localStorage.getItem('scrollToContact') === 'true';

    if (scrollToContact) {
      // Scroll to the ContactMe section using react-scroll when the component mounts
      scroller.scrollTo('ContactMe', {
        duration: 500,
        smooth: 'easeInOutQuart',
      });

      // Clear the flag in localStorage after scrolling
      localStorage.removeItem('scrollToContact');
    }
  }, []);

  return (
    <div className='MainContent'>
        <Introduction/>
        <Skills/>
        <ExperianceCarousel/>
        <Projects/>
        <ContactMe/>
        <Footer/>
    </div>
  )
}

export default MainContent