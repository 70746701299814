// import React, { useRef, useState } from "react";
// export const Context = React.createContext();
// export const ContextProvider = ({ children }) => {
//     const intialized = useRef(false)
//     const [mode,setMode] = useState('');
//     return(
//         <Context.Provider value={{intialized,mode,setMode}}>
//                 {children}
//         </Context.Provider>
//     )
// }



import React, { useRef, useState, createContext, ReactNode } from "react";

export interface ContextType {
  initialized: React.MutableRefObject<boolean>;
  mode: string;
  setMode: React.Dispatch<React.SetStateAction<string>>;
}

export const Context = createContext<ContextType | undefined>(undefined);

interface ContextProviderProps {
  children: ReactNode;
}

export const ContextProvider = ({ children }: ContextProviderProps) => {
  const initialized = useRef<boolean>(false);
  const [mode, setMode] = useState<string>('');

  const contextValue: ContextType = {
    initialized,
    mode,
    setMode
  };

  return (
    <Context.Provider value={contextValue}>
      {children}
    </Context.Provider>
  );
};
