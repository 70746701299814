import { useEffect } from "react";

export function useToggleModes(
  initialization: React.MutableRefObject<boolean>,
  state: any, // Replace 'any' with the appropriate type for your state
  setState: React.Dispatch<React.SetStateAction<any>> // Replace 'any' with the appropriate type for your setState
) {
  useEffect(() => {
    const currentMode = localStorage.getItem('Mode');
    if (initialization.current === false) {
      if(currentMode === null){
          localStorage.setItem("Mode","Light");
          setState("Light");
      }
      else{
        if(currentMode === "Light"){
            document.documentElement.setAttribute('data-theme', 'light');
        }
        if(currentMode === "Dark"){
            document.documentElement.setAttribute('data-theme', 'dark');
            const checkbox = document.getElementById("theme-toggle") as HTMLInputElement;
            console.log(checkbox)
            if (checkbox) {
                checkbox.checked = true;
            }
        }
      }
      initialization.current = true;
    } else {
      if(state === "Dark"){
        document.documentElement.setAttribute('data-theme', 'dark');
        localStorage.setItem("Mode","Dark");
      }
      if(state === "Light"){
        document.documentElement.setAttribute('data-theme', 'light');
        localStorage.setItem("Mode","Light");
      }
      console.log("initialization == true");
    }
  }, [state]);
}
