import React, { useState, useRef } from 'react';
import "components/ContactMe/ContactMe.css";
import { ReactComponent as Illustration} from "styles/assets/UndrawSVG.svg";
import { handleContactMeSubmit } from 'utils/handleContactMeSubmit';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ContactMe() {

    const [contactMeName, setContactMeName] = useState<string>('');
    const [contactMeEmail, setContactMeEmail] = useState<string>('');
    const [contactMeMessage, setContactMeMessage] = useState<string>('');

    let [contactMeFormState,setContactMeFormState] = useState(false);
    let contactMeNameRef = useRef<HTMLInputElement>(null)
    let contactMeEmailRef = useRef<HTMLInputElement>(null)
    let contactMeMessageRef = useRef<HTMLTextAreaElement>(null)

    const handleMailButtonClick = () => {
        const emailAddress = "ofirsegal99@gmail.com"
        const mailtoLink = `mailto:${emailAddress}`;
        window.location.href = mailtoLink;
      };


    const form = useRef<HTMLFormElement>(null);

    const sendEmail = (e:any) => {
      e.preventDefault();
      if(form.current){
          emailjs.sendForm('service_0a6vegp', 'template_scnrh3m', form.current, 'aznZ1jWnobdDCcH3-')
            .then((result) => {
                handleContactMeSubmit(contactMeFormState,setContactMeFormState)
            }, (error) => {
                toast.error('An error has occurred. Try again later..', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            });
      }
    };
  return (
    <div id='ContactMe' className='MainContentChild'>
        <div className='ContactMe'>
            {
                contactMeFormState ?
                <div className='ContactMeFormSucessMessage'>
                    <div>
                    {`Dear ${contactMeNameRef.current?.value}, thank you for submitting your form! I'm delighted to inform you that your information has been successfully received.`}
                    </div>
                    <div>
                    Rest assured, I will personally reach out to you very soon. Looking forward to connecting with you!
                    </div>
             </div>
                :
                <>
             <form ref={form} autoComplete="off" className='ContactMeFirstChild'  onSubmit={(e) => {sendEmail(e)}}>
                <div className='ContactMeSection'>
                     <h1 className='commonSeconderyTitle'>
                         Let's chat about us bringing greatness togehter !
                     </h1>
                     <div className='ContactMeThroughEmailContainer'>
                         <span className='commonSeconderyText emailSpan'>You can reach me anytime via </span>
                         <button onClick={() => {handleMailButtonClick()}} className='Button ContactMeEmailButton'>ofirsegal99@gmail.com</button>
                     </div>
                </div>
                <div className='ContactMeSection'>                    
                    <div className='ContactMeInputContainer'>
                        <label className='ContactMeInputLabel' htmlFor="ContactMeNameInput">Name</label>
                        <input ref={contactMeNameRef} autoComplete="off" required name='from_name' className='ContactMeInput' id='ContactMeNameInput' placeholder='Your name' type="text" value={contactMeName}
                            onChange={(e) => setContactMeName(e.target.value)}/>
                    </div>
                    <div className='ContactMeInputContainer'>
                        <label className='ContactMeInputLabel' htmlFor="ContactMeEmailInput">Email</label>
                        <input ref={contactMeEmailRef}  required name='user_email' className='ContactMeInput' id='ContactMeEmailInput' placeholder='You@company.com' type="email" value={contactMeEmail}
                             onChange={(e) => setContactMeEmail(e.target.value)} autoComplete="email-off"/>
                    </div>
                    <div className='ContactMeInputContainer'>
                        <label className='ContactMeInputLabel' htmlFor="ContactMeMessageInput">Message</label>
                        <textarea ref={contactMeMessageRef} autoComplete="off" required name='message' className='ContactMeInput' id='ContactMeMessageInput' rows={7} placeholder='Tell me a little about yourself/the company...'              value={contactMeMessage}
                                onChange={(e) => setContactMeMessage(e.target.value)} />
                    </div>
                </div>
                {/* <button onClick={() => {handleContactMeSubmit(contactMeFormState,setContactMeFormState);}} className='V5Button ContactMeSubmitButton'>
                    Let's get started
                </button> */}
                <input type='submit' value="Let's get started" className='V5Button ContactMeSubmitButton'/>
            </form>
            <div className='ContactMeSecondChild'>
            <Illustration width="60%"/>
            </div>
                </>
            }
        </div>
    </div>            
  )
}

export default ContactMe