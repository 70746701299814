import React, { useEffect, useContext } from 'react'
import NavBar from './layout/NavBar/NavBar'
import "./styles/root.css"
import MainContent from 'layout/MainContent/MainContent'
import { useToggleModes } from 'utils/useToggleModes';
import { Context, ContextType } from 'context/Context';
import { Route, Routes, useLocation } from 'react-router-dom';
import NotFoundPage from 'layout/NotFoundPage';
import Project from 'layout/Project/Project';
import { projects } from 'constants/projects';
import SideBar from 'layout/SideBar/SideBar';



function Main() {
  const context = useContext(Context);
  const {initialized,mode,setMode} = context || ({} as ContextType);
  
  useToggleModes(initialized,mode,setMode);

  const navBarObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      console.log(entry)
      if (entry.isIntersecting) {
        // Add the "isSticky" class when the element becomes sticky
        entry.target.classList.remove('isSticky');
      } else {
        // Remove the "isSticky" class when the element is no longer sticky
        entry.target.classList.add('isSticky');
      }
    });
  }, {
    // Set the rootMargin to trigger the intersection a bit earlier or later
    rootMargin: '-13% 0px 0px 0px', 
    // Set the threshold to 0 so that it triggers when any part of the element is visible
    threshold: 0
  });

useEffect(() => {
  const navBar = document.getElementById('NavBarContainer');
  if(navBar) {navBarObserver.observe(navBar);}

  return () => {
    navBarObserver.disconnect();
  };
},[])

const location = useLocation();

useEffect(() => {
  window.scrollTo(0, 0);
}, [location.pathname]);


  return (
    <div className='Main'>
        <NavBar/>
        <SideBar/>
        <Routes>
              <Route path="/" element={<MainContent/>}/>
              <Route path='/Project/2023Portfolio' element={<Project currentProject={projects[0]}/>}/>
              <Route path='/Project/Recipes' element={<Project currentProject={projects[1]}/>}/>
              <Route path='/Project/2021Portfolio' element={<Project currentProject={projects[2]}/>}/>
              <Route path='/Project/Barbershop' element={<Project currentProject={projects[3]}/>}/>
              <Route path='/*' element={<NotFoundPage/>}/>

        </Routes>
    </div>
  )
}

export default Main
