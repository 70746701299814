import React from 'react';
import "./Introduction.css";
import Svg from 'common/Svg';
import Profile from 'styles/assets/OfirImage.png';
import { Link as ScrollLink } from 'react-scroll';


function Introduction() {
  const emailLogo = 
  <svg className='IntroductionFooterLogo' version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000"
 preserveAspectRatio="xMidYMid meet">

<g className='IntroductionFooterLogo' transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M185 375 c72 -68 132 -124 135 -124 3 0 63 56 135 124 l130 124 -265
1 -265 0 130 -125z"/>
<path d="M40 320 l0 -161 28 20 c15 11 54 47 87 80 l59 61 -59 61 c-33 33 -72
69 -87 80 l-28 20 0 -161z"/>
<path d="M510 402 l-85 -82 85 -79 85 -79 3 79 c2 44 2 116 0 161 l-3 82 -85
-82z"/>
<path d="M145 226 l-90 -85 265 -1 265 0 -87 87 -88 88 -45 -45 -45 -44 -43
42 -43 42 -89 -84z"/>
</g>
</svg>
  

  const handleMailButtonClick = () => {
    const emailAddress = "ofirsegal99@gmail.com"
    const mailtoLink = `mailto:${emailAddress}`;
    window.location.href = mailtoLink;
  };


  const linkedinLogo = <svg className='IntroductionFooterLogo' xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px">    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"/></svg>
  return (
    <div className='Introduction'>
      <div className='IntroductionHalfContainer ObserveEntity LeftObserveEntity'>
        <div>
            <span className='IntroductionMainTitle'>
            Ofir Segal: Innovative Frontend/FS Developer
            </span>
        </div>
        <div>
            <span className='IntroductionSubTitle'>
            Merging Innovation and Artistry: With Over 2 Years of Profound Experience, I Craft Intuitive User Experiences, Transforming Ideas into Exquisite Digital Realities with a Passion for Design and Code
            </span>
        </div>
        <div className='IntroductionButtonsContainer'>
          <ScrollLink to='ContactMe' offset={-30} className='V5Button'>
            Contact me
          </ScrollLink>
          <ScrollLink  to='Projects' offset={-100} className='V4Button'>
            Project gallery
          </ScrollLink>
        </div>
        <div className='IntroductionFooter'>
          Other options to contact me
          <div style={{display:"flex",gap:"5%"}}>
              <a href='https://www.linkedin.com/in/ofir-segal/' target="_blank" className='V1Button IntroductionFooterButton'>
                <Svg svg={linkedinLogo}/>
              </a>
              <button onClick={() => {handleMailButtonClick()}} className='V1Button IntroductionFooterButton Button'>
                <Svg svg={emailLogo}/>
              </button>
          </div>
        </div>
      </div>
      <div className='IntroductionHalfContainer IntroductionSecondHalfContainer ObserveEntity RightObserveEntity'>
          <div className='IntroductionProfilePictureContainer'>
            <div className='IntroductionProfile'>
              <div className='IntroductionProfilePictureBackground'></div>
              <img className='IntroductionProfilePicture' src={Profile} alt="introduction-profile-pic" />
            </div>
        </div>
      </div>
      </div>
  )
}

export default Introduction