import React, { useContext, useState, useEffect } from 'react';
import "./NavBar.css";
import Svg from "common/Svg";
import { ReactComponent as ExpandSVG} from "styles/assets/ExpandSVG.svg"
import { Link as RouterLink, useNavigate, useLocation} from 'react-router-dom';
import { Context, ContextType } from 'context/Context';
import { projects } from 'constants/projects';

function NavBar() {
  const navBarLogo = <svg className='NavBarLogo' version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 651.000000 432.000000"
  preserveAspectRatio="xMidYMid meet">
 
 <g transform="translate(0.000000,432.000000) scale(0.100000,-0.100000)"
 fill="#000000" stroke="none">
 <path className='NavBarLogoLetter' d="M1768 3484 c-186 -45 -333 -198 -368 -383 -14 -76 -14 -1728 1 -1807
 46 -250 299 -433 547 -394 214 34 382 203 411 414 14 100 15 1672 1 1771 -39
 276 -320 465 -592 399z m185 -351 c15 -8 37 -28 47 -46 19 -31 20 -57 20 -894
 l0 -862 -30 -35 c-71 -80 -188 -68 -235 24 -22 43 -23 1716 -1 1759 19 36 37
 50 83 66 40 14 70 11 116 -12z"/>
 <path className='NavBarLogoLetter' d="M3590 2205 l0 -1295 170 0 170 0 0 1295 0 1295 -170 0 -170 0 0
 -1295z"/>
 <path className='NavBarLogoLetter' d="M2550 2200 l0 -1290 170 0 170 0 0 675 0 675 255 0 255 0 0 170 0
 170 -255 0 -255 0 0 275 0 275 255 0 255 0 0 170 0 170 -425 0 -425 0 0 -1290z"/>
 <path className='NavBarLogoLetter' d="M4110 2190 l0 -1300 175 0 175 0 0 276 0 275 41 -3 41 -3 98 -268
 c54 -148 100 -270 103 -273 2 -2 85 -3 184 -2 l180 3 -88 239 c-48 131 -98
 267 -110 301 l-22 62 65 65 c75 75 113 146 128 238 12 80 13 1216 1 1312 -24
 187 -171 338 -357 368 -33 6 -184 10 -336 10 l-278 0 0 -1300z m575 946 c18
 -8 38 -23 44 -34 8 -14 11 -216 11 -641 l0 -620 -24 -28 c-24 -27 -27 -28
 -140 -31 l-116 -4 0 686 0 686 95 0 c60 0 108 -5 130 -14z"/>
 </g>
 </svg>
  const [isResumeExpanded, setResumeExpanded] = useState(false);
  const [isProjectsExpanded, setProjectsExpanded] = useState(false);
  const context = useContext(Context);
  const {setMode} = context || ({} as ContextType);



const navigate = useNavigate();
const location = useLocation()
// Function to handle logo click
const handleLogoClick = () => {
  // Check if the current location is already at "/"
  if (location.pathname === '/') {
    // If on the homepage, scroll to the top of the page
    window.scrollTo(0, 0);
  } else {
    // If not on the homepage, go back to the homepage using react-router's history
    navigate('/');
  }
};  




const handleMenuToggle = (setExpanded:any) => {
  setExpanded((prevExpanded:any) => !prevExpanded);
};

const handleOpen = () => {
  const fileUrl = process.env.PUBLIC_URL + '/Ofir Segal Resume.pdf';
  window.open(fileUrl, '_blank');
};
const handleDownload = () => {
  const fileUrl = process.env.PUBLIC_URL + '/Ofir Segal Resume.pdf';
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = 'Ofir Segal Resume.pdf'; // Specify the downloaded file name
  link.click();
};

const [scrollToContact, setScrollToContact] = useState(false);

const handleChatClick = () => {
  // Check if you are already on the main page ("/")
  if (window.location.pathname !== '/') {
    // If not on the main page, set the flag in localStorage to indicate that you want to scroll to the ContactMe section
    localStorage.setItem('scrollToContact', 'true');
  } else {
    // If already on the main page, immediately scroll to the ContactMe section
    setScrollToContact(true);
  }
};

  // Function to handle the scrolling to the ContactMe section
  const scrollToContactSection = () => {
    const contactMeSection = document.getElementById('ContactMe');
    if (contactMeSection) {
      contactMeSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (scrollToContact) {
      scrollToContactSection();
      setScrollToContact(false); // Reset the flag after scrolling
    }
  }, [scrollToContact]);
  return (
    <div id='NavBarContainer' className='NavBarContainer '>
      <div className='Layout NavBar '>
        <div className='NavBarLogoContainer'>
          <RouterLink style={{display:"flex",alignItems:"center"}} to="/" onClick={handleLogoClick}>
              <Svg svg={navBarLogo}/>
          </RouterLink>
        </div>
        <div className='NavBarButtonContainer'>
              <div className='ButtonDiv V1Button' onMouseEnter={() => {handleMenuToggle(setResumeExpanded)}}
            onMouseLeave={() => {handleMenuToggle(setResumeExpanded)}}>
                  <span className='V1Button'>Resume</span>
                  {isResumeExpanded ? <ExpandSVG className="ExpandSVG Up"/> : <ExpandSVG className="ExpandSVG Down"/>}
                  { isResumeExpanded && (
                       <div className='ExpandMenu'>
                        <div className='ExpandMenuButtonsContainer'>
                          <button className="Button V1Button" onClick={handleOpen}>Open</button>
                           <a href="#" className="Button V1Button" onClick={handleDownload}>Download</a>
                        </div>
                       </div>
                  )}
              </div>
              <div className='ButtonDiv V1Button' onMouseEnter={() => {handleMenuToggle(setProjectsExpanded)}}
            onMouseLeave={() => {handleMenuToggle(setProjectsExpanded)}}>
                  <span className='V1Button'>Projects</span>
                  {isProjectsExpanded ? <ExpandSVG className="ExpandSVG Up"/> : <ExpandSVG className="ExpandSVG Down"/>}
                  { isProjectsExpanded && (
                       <div className='ExpandMenu'>
                        <div className='ExpandMenuButtonsContainer'>
                          {projects.map((curr,i) => {
                            return(
                              <RouterLink to={curr.route} key={i} className="Button V1Button">{curr.name}</RouterLink>
                            )
                          })}
                        </div>
                       </div>
                  )}
              </div>
        </div>
        <div className='V2ButtonContainer'>
         <div className="container">
            <input onChange={(e) => {e.target.checked ? setMode("Dark"):setMode("Light")}} type="checkbox" id="theme-toggle"/>
            <label htmlFor="theme-toggle"></label>
          </div>
          {/* <ScrollLink to='ContactMe' offset={-30} className='V2Button'>
            <span>
            Let's chat
            </span>
          </ScrollLink> */}
          <RouterLink to='/' onClick={handleChatClick} className='V2Button'>
            <span>
            Let's chat
            </span>
          </RouterLink>
        </div>
      </div>
    </div>
  )
}

export default NavBar