import React, { useEffect, useState } from 'react'
import "components/Skills/Skills.css"
import Select from 'react-select'
import { skills } from 'constants/skills'

function Skills() {  
   const listOfSkills = skills;
   const options = [
      { value: 'Any', label: 'Any' },
      { value: 'Front-end', label: 'Front-end' },
      { value: 'Back-end', label: 'Back-end' },
      { value: 'Design', label: 'Design' },
      { value: 'Tools', label: 'Tools' },
      { value: 'Data-base', label: 'Data-base' },
    ]  
   const [stateOfSelectList,setStateOfSelectList] = useState(listOfSkills);
   const [selectValue,setSelectValue] = useState(options[0]);
useEffect(() => {
   if(!(selectValue.value === "Any")){
      let newArray: any[] = [];
      listOfSkills.forEach(element => {
         if(element.type === selectValue.value){
            newArray = [...newArray,element]
         }
      });
      setStateOfSelectList(newArray);
   }
   else setStateOfSelectList(listOfSkills)
},[selectValue])

  return (
    <div className='MainContentChild'>
      <div className='commonSectionContainer'>
        <h2 className='commonTitle'>
        Skillforge: Unleashing the Digital Mastery
        </h2>
        <div className='commonGreenBar'></div>
      </div>
      <div className='commonDescription'>
          <span className="commonText">
          Welcome to my frontend developer skills section. Here, I showcase the diverse range of skills I have acquired throughout my journey as a frontend developer. These skills encompass various aspects of web development, including design, tools, back-end, front-end, and more. You can easily filter through the different skill types to explore specific areas of interest. Whether it's mastering the latest design trends, leveraging powerful development tools, or crafting seamless user experiences, this section highlights my expertise in the multifaceted world of frontend development.          </span>
        </div>
            <Select
            className="SkillsSelect"
            styles={{
               control: (baseStyles, state) => ({
                 ...baseStyles,
                 background:"#d4fc79",
                 borderColor: state.isFocused ? '#d4fc79' : '#96e6a1',
               }),
             }}
            onChange={(e)=>{  if (e?.value) {
               setSelectValue({ value: e.value, label: e.label });
             } }}
            theme={(theme) => ({
               ...theme,
               borderRadius: 0,
               colors: {
                 ...theme.colors,
                 primary25: '#96e6a1',
                 primary: '#d4fc79',

               },
             })}
            defaultValue={options[0]}
             options={options} 
             />
        <div className='Skills'>
        {stateOfSelectList.map((curr,i) => {
         return(
                <div key={i} className='SkillsCard'>
                      {curr.svg}
                      <div className='SkillsCardType'>
                         {curr.type}
                      </div>
                      <h2 className='SkillsCardName'>
                        {curr.name}
                      </h2>
                </div>
         )
        })}
        </div>
    </div>
  )
}

export default Skills