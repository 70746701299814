
import React from "react";
import Main from "./Main";
import { ContextProvider } from 'context/Context';
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <ContextProvider>
        <BrowserRouter>
              <div className="App">
                 <Main/>
              </div>
        </BrowserRouter>
    </ContextProvider>
  );
}

export default App;
