import React from 'react'
import "components/Projects/Projects.css"
import { projects } from 'constants/projects';
import { Link as RouterLink } from 'react-router-dom';

function Projects() {
  return (
    <div id='Projects' className='MainContentChild'>
      <div className='commonSectionContainer'>
        <h2 className='commonTitle'>
        Immersive Frontend Experiences
        </h2>
        <div className='commonGreenBar'></div>
      </div>
        <div className='commonDescription'>
          <span className="commonText">
        Welcome to my portfolio showcasing a collection of frontend projects that combine stunning design, flawless code, and seamless user experiences. Each project is crafted with meticulous attention to detail, creating visually captivating interfaces that engage and delight users. Dive into the world of cutting-edge frontend technologies, innovative UI/UX designs, and pixel-perfect implementations. Get ready to explore a gallery of inspiring web applications where creativity meets functionality, and user satisfaction is the ultimate goal.
          </span>
        </div>
        <div className='ProjectsCardContainer'>
        {projects.map((curr,i) => {
          return(
            <div key={i} className='ProjectsSingleCard ObserveEntity '>
            <span className='ProjectSingleCardTitle'>
              {curr.name}
            </span>
            <div className='ProjectsSingleCardDescription'>
              <div className='commonSeconderyText'>
                {curr.description}
              </div>
              <div className='ProjectsSingleCardSecondDescription'>
                <ul className='ProjectsSingleCardSecondDescriptionUL'>
                  {curr.bulletPoints.map((singleBullet,i) => {
                    return(
                      <li key={i} className='commonSeconderyText li'>
                      {singleBullet}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
              <div>
                    <RouterLink to={curr.route} className='V5Button ProjectsSingleCardButton'>
                      View project
                    </RouterLink>
              </div>
          </div>
          )
        })}
        </div>
    </div>
  )
}

export default Projects;