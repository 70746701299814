import React, { useState, useEffect, useRef } from 'react'
import "layout/SideBar/SideBar.css"
import { ReactComponent as Arrow} from "styles/assets/ArrowSvg.svg"
import { projects } from 'constants/projects';
import { Link as RouterLink } from 'react-router-dom';


function SideBar() {
    const sideBarRef = useRef<HTMLDivElement>(null)
    const [toggleSideBar,setToggleSideBar] = useState(false)

    const buttonRef = useRef<HTMLButtonElement>(null)
    useEffect(() => {
        if(toggleSideBar){
            sideBarRef.current?.classList.add("SideBarOpen")
            buttonRef.current?.classList.add("GoRight")
        }
        else{
            sideBarRef.current?.classList.remove("SideBarOpen")
            buttonRef.current?.classList.remove("GoRight")

        }
    },[toggleSideBar])


      const handleOpen = () => {
        const fileUrl = process.env.PUBLIC_URL + '/Ofir Segal Resume.pdf';
        window.open(fileUrl, '_blank');
      };

      const handleDownload = () => {
        const fileUrl = process.env.PUBLIC_URL + '/Ofir Segal Resume.pdf';
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'Ofir Segal Resume.pdf'; // Specify the downloaded file name
        link.click();
      };


  return (
        <div ref={sideBarRef} className='SideBarContainer'>
            <div className='SideBarArrowsContainer'>
                <div>
                </div>
                <button ref={buttonRef} style={{padding:"0px",height:"48px"}} onClick={() => {setToggleSideBar(!toggleSideBar)}} className='Button SideBarButton'>
                    <Arrow className="SideBarReturnArrowSvg" height="48px"/>
                </button>
            </div>
                <div className='SideBar'>
                    <div className='SideBarButtonContainer'>
                        <div className='ButtonDiv V1Button'>
                            <span className='commonThirdlyTitle'>Resume :</span>
                                <div className='SideBarExpandMenu'>
                                    <div className='ExpandMenuButtonsContainer'>
                                    <button className="Button V1Button" onClick={() => {handleOpen();setToggleSideBar(false);}}>Open</button>
                                    <a href="#" className="Button V1Button" onClick={() =>{handleDownload();setToggleSideBar(false);}}>Download</a>
                                    </div>
                                </div>
                        </div>
                        <div className='ButtonDiv V1Button'>
                            <span className='commonThirdlyTitle'>Projects :</span>
                                <div className='SideBarExpandMenu'>
                                    <div className='ExpandMenuButtonsContainer'>
                                    {projects.map((curr,i) => {
                                        return(
                                        <RouterLink to={curr.route} onClick={() => {setToggleSideBar(false)}} key={i} className="Button V1Button">{curr.name}</RouterLink>
                                        )
                                    })}
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
        </div>
  )
}

export default SideBar