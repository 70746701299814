import { ReactComponent as JavaScript} from "styles/assets/java-script.svg"
import { ReactComponent as Sass} from "styles/assets/sass.svg"
import { ReactComponent as CSS} from "styles/assets/css3.svg"
import { ReactComponent as Photoshop} from "styles/assets/photoshop.svg"
import { ReactComponent as HTML} from "styles/assets/html5.svg"
import { ReactComponent as TypeScript} from "styles/assets/typescript.svg"
import { ReactComponent as Bootstrap} from "styles/assets/bootstrap.svg"
import { ReactComponent as Git} from "styles/assets/git.svg"
import { ReactComponent as Github} from "styles/assets/github.svg"
import { ReactComponent as ReactIcon} from "styles/assets/react.svg"
import { ReactComponent as AzureDevops} from "styles/assets/azure-devops.svg"
import { ReactComponent as SQL} from "styles/assets/sql.svg"
import { ReactComponent as Figma} from "styles/assets/figma.svg"
import { ReactComponent as Angular} from "styles/assets/angular.svg"
import { ReactComponent as MongoDB} from "styles/assets/mongodb.svg"
import { ReactComponent as NodeJS} from "styles/assets/node-js.svg"
import React from "react"

export const skills = [
    {
        type: "Front-end",
        name:"React",
        svg:<ReactIcon height="64px"/>   
    },
    {
        type: "Front-end",
        name:"CSS",
        svg:<CSS height="64px"/>  
    },
    {
        type: "Front-end",
        name:"JavaScript",
        svg:<JavaScript height="64px"/> 
    },
    {
        type: "Front-end",
        name:"SASS",
        svg:<Sass height="64px"/>  
    },
    {
        type: "Front-end",
        name:"Angular",
        svg:<Angular height="64px"/> 
    },
    {
        type: "Design",
        name:"Photoshop",
        svg:<Photoshop height="64px"/>   
    },
    {
        type: "Front-end",
        name:"HTML",
        svg:<HTML height="64px"/>   
    },
    {
        type: "Front-end",
        name:"TypeScript",
        svg:<TypeScript height="64px"/>
    },
    {
        type: "Front-end",
        name:"Bootstrap",
        svg:<Bootstrap height="64px"/>
    },
    {
        type: "Tools",
        name:"Git",
        svg:<Git height="64px"/>
    },
    {
        type: "Tools",
        name:"Github",
        svg:<Github height="64px"/>
    },
    {
        type: "Data-base",
        name:"MongoDB",
        svg:<MongoDB height="64px"/>
    },
    {
        type: "Back-end",
        name:"NodeJS",
        svg:<NodeJS height="64px"/>
    },
    {
        type: "Design",
        name:"Figma",
        svg: <Figma height="64px"/>
    },
    {
        type: "Tools",
        name:"Azure Devops",
        svg: <AzureDevops height="64px"/>
    },
    {
        type: "Data-base",
        name:"SQL",
        svg:  <SQL height="64px"/>
    },
]