import Portfolio2023Desktop from "styles/assets/2023PortfolioDesktop.mp4";
import Portfolio2023Tablet from "styles/assets/2023PortfolioTablet.mp4";
import Portfolio2023Mobile from "styles/assets/2023PortfolioMobile.mp4";
import Portfolio2021Desktop from "styles/assets/2021PortfolioDesktop.mp4";
import Portfolio2021Tablet from "styles/assets/2021PortfolioTablet.mp4";
import Portfolio2021Mobile from "styles/assets/2021PortfolioMobile.mp4";
import RecipesDesktop from "styles/assets/RecipesDesktop.mp4";
import RecipesTablet from "styles/assets/RecipesTablet.mp4";
import RecipesMobile from "styles/assets/RecipesMobile.mp4";
import BarbershopDesktop from "styles/assets/BarbershopDesktop.mp4";
import BarbershopTablet from "styles/assets/BarbershopTablet.mp4";
import BarbershopMobile from "styles/assets/BarbershopMobile.mp4";

export const projects = [
    {
        "name":"2023 Portfolio",
        "description": "Introducing my 2023 Portfolio, a testament to my skills and design prowess. Developed using React and Typescript, this project embodies two years of growth. Meticulously crafted components ensure easy integration and maintenance. With a handmade, adaptive UI/UX design, it offers optimal viewing on various devices. Explore my resume, contact services, and delve into my background for detailed insights. This project embraces solid principles, excellent UI/UX fundamentals, and achieves high performance through optimized styles and virtual DOM concepts.",
        "bulletPoints":[
            "Developed with React and Typescript, showcasing more than two years of experience.",
            "Emphasizes reusability and modularity, with all components designed to be highly reusable.",
            "Features a handmade UI/UX design, with each component styled by the developer.",
            "Fully adaptive and responsive, providing an optimal viewing experience on any device.",
            "Showcases resume information and offers a resume download option.",
            "Built with a deep understanding of solid principles and object-oriented programming.",
            "Exhibits excellent UI/UX fundamentals for a visually appealing and user-friendly interface.",
            "Offers dark and light modes for enhanced user experience.",
            "Optimizes component styles and leverages virtual DOM concepts for high performance",
        ],
        "route":"/Project/2023Portfolio",
        "skills":["React","TypeScript","SASS","Photoshop","Github","Git"],
        "videos":[{
            "type":"Desktop",
            "video":Portfolio2023Desktop
        },
        {
            "type":"Tablet",
            "video":Portfolio2023Tablet
        },
        {
            "type":"Mobile",
            "video":Portfolio2023Mobile
        }]
    },
    {
        "name":"Recipes",
        "description": "The Recipes project, built with React JavaScript, is a visually appealing and user-friendly library that provides a vast collection of recipes for every culinary enthusiast. With a focus on enhancing UI/UX design, this project allowed me to dive deeper into creating visually stunning interfaces. The project features a versatile search function, enabling users to find specific recipes of their choice. Additionally, a meticulously crafted carousel showcases recipes by different ethnicities, while categories like main course or drinks offer easy navigation. Handmade UI/UX elements and a strong emphasis on component reusability contribute to an optimized user experience. The project is fully adaptive and responsive, ensuring seamless functionality on devices with a minimum width of 280px.",
        "bulletPoints":[
            "Developed with React JavaScript, emphasizing UI/UX design.",
            "Features a flexible search function for finding specific recipes.",
            "Utilizes a visually appealing carousel to highlight recipes by different ethnicities.",
            "Categorizes recipes into sections such as main course or drinks for easy navigation.",
            "Incorporates handmade UI/UX elements for an optimized user experience.",
            "Emphasizes component modularity and reusability for efficient development.",
            "Fully supports adaptiveness and responsiveness across various devices.",
        ],
        "route":"/Project/Recipes",
        "skills":["React","JavaScript","SASS","Github","Git"],
        "videos":[{
            "type":"Desktop",
            "video":RecipesDesktop
        },
        {
            "type":"Tablet",
            "video":RecipesTablet
        },
        {
            "type":"Mobile",
            "video":RecipesMobile
        }]
    },
    {
        "name":"2021 Portfolio",
        "description": "Experience my 2021 Portfolio a showcase of my frontend development skills. This single-page application, built with React JavaScript, captures the essence of a modern and responsive website. It features an open resume option, resume download functionality, and a contact form integrated with a reliable third-party service. Toast messages provide real-time feedback. The portfolio demonstrates adaptiveness and responsiveness across mobile, tablet, and desktop devices.",
        "bulletPoints":[
            "Developed in 2021 as my first portfolio project as a frontend developer.",
            "Built using React JavaScript for a modern and dynamic user interface.",
            "Captures the essence of a single-page application for smooth navigation.",
            "Implements open resume functionality, allowing visitors to view my resume directly.",
            "Provides the option for resume download, enabling users to easily access my credentials. ",
            "Integrates a contact form with a reliable third-party service for seamless communication.",
            "Utilizes toast messages to provide real-time feedback on form submissions.",
            "Demonstrates full adaptiveness and responsiveness for optimal viewing on any device.",
            "Showcases a collection of my projects, highlighting my skills and expertise.",
        ],
        "route":"/Project/2021Portfolio",
        "skills":["React","JavaScript","CSS","Github","Git"],
        "videos":[{
            "type":"Desktop",
            "video":Portfolio2021Desktop
        },
        {
            "type":"Tablet",
            "video":Portfolio2021Tablet
        },
        {
            "type":"Mobile",
            "video":Portfolio2021Mobile
        }]
    },{
        "name":"Barbershop",
        "description": "The Barbershop project was my initial venture into frontend development, focusing on CSS and HTML. It aimed to provide a basic understanding of web development principles by integrating a Google Maps iframe for location information and a CSS-only carousel to showcase different haircut styles. This project served as a stepping stone to grasp frontend essentials, including design patterns and product ideas, before diving into frameworks like React and Angular.",
        "bulletPoints":[
            "First project in CSS and HTML, marking the beginning of my frontend development journey.",
            "Focuses on capturing the essence of web development and understanding its principles.",
            "Integrated a Google Maps iframe to showcase the barbershop's location and information.",
            "Implemented a CSS-only carousel to showcase various haircut styles.",
            "Served as a stepping stone to grasp the essentials of frontend development, including design patterns and product ideas.",
            "Emphasized learning the fundamentals of JavaScript, CSS, and HTML before exploring frameworks like React and Angular.",
            "Acquired practical frontend development skills and prepared for more complex projects in the future.",
        ],
        "route":"/Project/Barbershop",
        "skills":["HTML","JavaScript","CSS","Github","Git"],
        "videos":[{
            "type":"Desktop",
            "video":BarbershopDesktop
        },
        {
            "type":"Tablet",
            "video":BarbershopTablet
        },
        {
            "type":"Mobile",
            "video":BarbershopMobile
        }]
    }
]