import React from 'react'
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className='MainContentChild'>
        <span className='commonText'>
        {`Copyrights © Ofir segal ${currentYear} All Rights Reserved`}
        </span>
    </footer>
  )
}

export default Footer