import React, { ReactElement } from 'react'

interface SvgProps {
  svg: ReactElement;
}
function Svg({svg} : SvgProps) {
  const svgurl = svg;
  return (
    <>
        {svgurl}
    </>
  )
}

export default Svg