import React, { FC, useEffect, useRef, useState } from 'react'
import "layout/Project/Project.css"
import { skills } from 'constants/skills'
import Footer from 'components/Footer/Footer';

interface ProjectProps {
    currentProject: {
      name: string;
      description: string;
      bulletPoints: string[];
      route: string;
      skills:string[];
      videos:any[];
    };
  }

const Project:FC<ProjectProps> = ({currentProject}) => {
    const videoDesktop = currentProject.videos[0]
    const videoTablet = currentProject.videos[1]
    const videoMobile = currentProject.videos[2]
    console.log(videoDesktop.video)
    const mobileTab = useRef<HTMLButtonElement>(null);
    const tabletTab = useRef<HTMLButtonElement>(null);
    const desktopTab = useRef<HTMLButtonElement>(null);
    const [changingMedia,setChangingMedia] = useState<any>(videoDesktop.video)
    const requiredSkills = currentProject.skills;
    const filteredSkills = skills.filter((skill) => requiredSkills.includes(skill.name))


    useEffect(() => {
        setChangingMedia(videoDesktop.video);
      }, [currentProject]);

const HandleTabChange = (video:any,type:string) => {
    setChangingMedia(video)
        switch (type) {
            case "Desktop":
                desktopTab.current?.classList.add("VideoTabButtonActive")
                tabletTab.current?.classList.remove("VideoTabButtonActive")
                mobileTab.current?.classList.remove("VideoTabButtonActive")
             break;
             case "Tablet":
                tabletTab.current?.classList.add("VideoTabButtonActive")
                desktopTab.current?.classList.remove("VideoTabButtonActive")
                mobileTab.current?.classList.remove("VideoTabButtonActive")
    
             break;
             case "Mobile":
                mobileTab.current?.classList.add("VideoTabButtonActive")
                tabletTab.current?.classList.remove("VideoTabButtonActive")
                desktopTab.current?.classList.remove("VideoTabButtonActive")
             break;   
            default:
                break;
        }
}
    return (
    <div className='MainContentChild' style={{paddingBottom:"0"}}>
        <div className='ProjectContainer'>
            <h2 className='IntroductionMainTitle'>
                {currentProject.name}
            </h2>
            <div className='ProjectSkillsContainer'>
                {filteredSkills.map((curr,i) => {
                    return(
                <div key={i} className='SkillsCard'>
                      {curr.svg}
                      <div className='SkillsCardType'>
                         {curr.type}
                      </div>
                      <h2 className='SkillsCardName'>
                        {curr.name}
                      </h2>
                </div>
                    )
                })}
            </div>
        </div>
        <div className='ProjectContainer'>
            <div className='DescriptionContainer'>
                <h2 className='commonThirdlyTitle'>
                    Description
                </h2>
                <div>
                    <span className='commonText'>
                        {currentProject.description}
                    </span>
                </div>
                <ul>
                    {currentProject.bulletPoints.map((curr,i)=> {
                     return(
                        <li className='commonText' key={i}>
                            <div className='ProjectsListItemLine'>
                            </div>
                            {curr}
                        </li>
                     )   
                    })}
                </ul>
            </div>
        </div>
        <div className='VideoContainer'>
            <div className='VideoTabsContainer'>
                <button ref={mobileTab} onClick={() => {HandleTabChange(videoMobile.video,videoMobile.type)}} className='Button V4Button VideoTabButton'>
                    Mobile
                </button>
                <button ref={desktopTab} onClick={() => {HandleTabChange(videoDesktop.video,videoDesktop.type)}} className='Button V4Button VideoTabButton VideoTabButtonActive'>
                    Desktop
                </button>
                <button ref={tabletTab} onClick={() => {HandleTabChange(videoTablet.video,videoTablet.type)}} className='Button V4Button VideoTabButton'>
                    Tablet
                </button>
            </div>
            <div className='MediaPlayer'>
            <video className='Media' src={changingMedia} controls></video>
                <Footer/>
            </div>
           
        </div>
    </div>
  )
}

export default Project